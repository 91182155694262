import * as Styled from "./style";
// import logo from "../../assets/logoExtendWhite.svg";
import {
  AiOutlineInstagram,
  // AiOutlineWhatsApp,
  // AiOutlineLinkedin,
  // AiOutlineFacebook,
} from "react-icons/ai";

export default function Footer() {
  return (
    <Styled.Footer>
      <div className="footer row">
        <div className="left col">
          {/* <img src={logo} alt="logo do doutor bom Custo" /> */}
          <p>
            Estamos comprometidos em apoiar o crescimento e o desenvolvimento de
            futuros profissionais da medicina oftalmológica brasileira. Junte-se
            a nós e dê o primeiro passo rumo a uma carreira gratificante e
            bem-sucedida na oftalmologia.
          </p>
          <br />
          <br />
          <p>
            Estamos localizados no seguinte endereço:{" "}
            <a
              href="https://www.google.com/maps/dir//Av.+Ipiranga,+344+-+Centro+Hist%C3%B3rico+de+S%C3%A3o+Paulo,+S%C3%A3o+Paulo+-+SP,+01046-010/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94ce584e671fdf25:0x104f0990b9956a4c?sa=X&ved=1t:707&ictx=111"
              target="_blanked"
            >
              Edifício Itália Avenida Ipiranga, 344 - 5⁰Andar - 52C CEP:
              01046-010 - República
            </a>
          </p>
        </div>
        <div className="socialGrid">
          <a href="https://www.instagram.com/epooftalmologia/" target="blanked">
            <div className="socialContainer row">
              <AiOutlineInstagram className="icon" />
              <p>Escola prática de oftalmologia</p>
            </div>
          </a>

          {/* <a
            href="https://api.whatsapp.com/send?phone=5511950212678&text=Ol%C3%A1!%20Eu%20gostaria%20de%20agendar%20uma%20consulta%20com%20Oftalmologista.%20"
            target="blanked"
          >
            <div className="socialContainer row">
              <AiOutlineWhatsApp className="icon" />
              <p>11 95021-2678</p>
            </div>
          </a>

          <a href="https://www.facebook.com/doutorbomcusto/" target="blanked">
            <div className="socialContainer row">
              <AiOutlineFacebook className="icon" />
              <p>Dr Bom Custo</p>
            </div>
          </a> */}
        </div>
      </div>
      <div className="privacypart row">
        {/* <p>Política de privacidade</p> */}
        <p>Copyright © 2023 Escola prática de oftalmologia </p>
        {/* <p>Termos de serviço</p> */}
      </div>
    </Styled.Footer>
  );
}
