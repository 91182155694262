import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/loading/index";

const Home = lazy(() => import("./pages/homePage/index"));
const Weeks3years = lazy(() =>
  import("./pages/gradeCursos/3xWeek3Years/index")
);
const Week1Year = lazy(() => import("./pages/gradeCursos/3xWeek1Years/index"));
const Month1Year = lazy(() => import("./pages/gradeCursos/1xMonth1Year/index"));
const Obrigado = lazy(() => import("./pages/obrigado/index"));
const OneWeek1Year = lazy(() =>
  import("./pages/gradeCursos/1xWeek1Years/index")
);
const CoursePage = lazy(() => import("./pages/course-page-3xWeek/index"));
export function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/curso-extensivo" element={<CoursePage />} />
        <Route path="/curso-prolongado" element={<CoursePage />} />

        {/* PAGINAS DA GRADE DO CURSO */}
        <Route path="/3Semanas3anos" element={<Weeks3years />} />
        <Route path="/3Semanas1ano" element={<Week1Year />} />
        <Route path="/1Semana1ano" element={<OneWeek1Year />} />
        <Route path="/1Mes1ano" element={<Month1Year />} />
        <Route path="/obrigado" element={<Obrigado />} />
      </Routes>
    </Suspense>
  );
}
