import styled from "styled-components";

const Media = {
  PhoneLarge: "@media(max-width:600px)",
  Laptop: "@media(max-width:1150px)",
  Tablet: "@media(max-width:1000px)",
  PhoneSmall: "@media(max-width:320px)",
};

export const Container = styled.div`
  position: fixed;
  width: 70%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background: var(--light);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  ${Media.PhoneLarge} {
    width: 95%;
  }
  form {
    margin: auto;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
  h3 {
    font-size: 1.8rem;
    color: var(--blue);
    text-align: center;
    margin-bottom: 1rem;
    ${Media.PhoneLarge} {
      font-size: 1.6rem;
    }
  }

  input {
    width: 80%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid var(--blueLight);
    font-size: 1rem;
    ${Media.PhoneLarge} {
      width: 95%;
    }

    &.Nome,
    &.Telefone {
      font-size: 1rem;
      ${Media.PhoneLarge} {
        font-size: 0.9rem;
      }
    }
  }

  .Button {
    width: 80%;
    padding: 0.8rem;
    background: var(--greenBold);
    color: #fff;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    ${Media.PhoneLarge} {
      width: 95%;
    }

    &:hover {
      background: var(--greenHover);
    }

    &:disabled {
      background: var(--gray);
      cursor: not-allowed;
    }
  }

  button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--black);
  }
`;
