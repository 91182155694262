import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Styled from "./style.js";

export default function Modal(props) {
  const { display, onClose, modalId } = props;
  const modalRef = useRef(null);
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [redirectMessage, setRedirectMessage] = useState("");

  const handleCloseModal = () => {
    onClose();
  };

  const getObservacao = () => {
    if (location.pathname === "/curso-extensivo") {
      return "Lead do curso extensivo";
    } else if (location.pathname === "/curso-prolongado") {
      return "Lead do curso Prolongado";
    } else if (location.pathname === "/") {
      return "Lead que veio da pagina inicial";
    } else {
      return "Lead de origem desconhecida";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowSubmitButton(false);
    setRedirectMessage("Redirecionando você para o contato...");

    const formData = new FormData(event.target);
    const data = {
      nome: formData.get("Nome"),
      telefone: formData.get("Telefone"),
      origem: "Google",
      observacoes: getObservacao(),
    };

    try {
      const response = await fetch("https://api.pipefy.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJQaXBlZnkiLCJpYXQiOjE3MTg3MzAzMTMsImp0aSI6IjBlMGY5ZTk2LTVmMjEtNDhkOC1hMGQ0LTkzMTBkNjczZTYxYSIsInN1YiI6MzA0ODc2MTQ5LCJ1c2VyIjp7ImlkIjozMDQ4NzYxNDksImVtYWlsIjoiZmVybmFuZG9hbHZlcy5vY3Vwb3BAZ21haWwuY29tIn19.27xePP1Idj4DJ9-93-A7--Wi5-Wa_Qc8NIAUBYB4660P9WeWFtddL-ztyLPWbeBvDZJNSfWLE9kcV-oFlpm-kg`,
        },
        body: JSON.stringify({
          query: `
            mutation {
              createCard(input: {
                pipe_id: 304487644,
                fields_attributes: [
                  { field_id: "nome", field_value: "${data.nome}" },
                  { field_id: "telefone", field_value: "${data.telefone}" },
                  { field_id: "origem", field_value: "${data.origem}" },
                ]
              }) {
                card {
                  id
                }
              }
            }
          `,
        }),
      });

      const result = await response.json();
      console.log(result);

      if (result.errors) {
        throw new Error("Erro ao criar o card no Pipefy");
      }

      let whatsappLink;
      if (location.pathname === "/curso-extensivo") {
        whatsappLink = `https://api.whatsapp.com/send?phone=5511952443023&text=Ol%C3%A1,%20eu%20quero%20saber%20sobre%20a%20p%C3%B3s-gradua%C3%A7%C3%A3o%20extensiva%20em%20oftalmologia`;
      } else if (location.pathname === "/curso-prolongado") {
        whatsappLink = `https://api.whatsapp.com/send?phone=5511952443023&text=Ol%C3%A1,%20eu%20quero%20saber%20sobre%20a%20p%C3%B3s-gradua%C3%A7%C3%A3o%20prolongada%20em%20oftalmologia`;
      } else {
        whatsappLink = `https://api.whatsapp.com/send?phone=5511952443023&text=Ol%C3%A1,%20eu%20quero%20saber%20sobre%20a%20p%C3%B3s-gradua%C3%A7%C3%A3o%20em%20oftalmologia`;
      }
      window.location.href = whatsappLink;

      handleCloseModal();
    } catch (error) {
      console.error("Erro ao enviar os dados para o Pipefy:", error);
      alert("Houve um erro ao enviar seus dados. Por favor, tente novamente.");
      setShowSubmitButton(true);
    } finally {
      setIsSubmitting(false);
      setRedirectMessage("");
    }
  };

  return (
    <Styled.Container
      id="id-form"
      style={{
        display: display ? "flex" : "none",
      }}
    >
      {redirectMessage && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {redirectMessage}
        </div>
      )}
      <form
        ref={modalRef}
        onSubmit={handleSubmit}
        className={`col formulario conversionForm-${modalId}`}
      >
        <h3>
          Preencha o formulário <br />e fale com um consultor pelo Whatsapp!
        </h3>
        <input
          type="text"
          name="Nome"
          required
          placeholder="Nome"
          pattern="^[A-Za-zÀ-ú\s]+$"
          className="Nome"
          data-input-id={`nome-${modalId}`}
        />
        <input
          type="text"
          name="Telefone"
          required
          placeholder="Telefone"
          pattern="^\+?(\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$"
          className="Telefone"
          data-input-id={`telefone-${modalId}`}
        />
        {showSubmitButton && (
          <input
            className="Button"
            type="submit"
            value="Fale com um consultor"
            disabled={isSubmitting}
          />
        )}
      </form>

      <button onClick={handleCloseModal}>X</button>
    </Styled.Container>
  );
}
