import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./reset.css";
// import GA4React from "ga-4-react";
import TagManager from "react-gtm-module";
import { Router } from "./router";
import Footer from "./components/footer";
import Header from "./components/header";
import WppButton from "./components/btnFixoWpp";
import ScrollToTop from "./ScrollToTop";
const root = ReactDOM.createRoot(document.getElementById("root"));

function Manager() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-MMKXBDQ7" });
  }, []);
}

// try {
//   setTimeout((_) => {
//     const ga4react = new GA4React("G-5Y075YFGMZ");
//     ga4react.initialize().catch((err) => console.error(err));
//   }, 4000);
// } catch (err) {
//   console.error(err);
// }

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Router />
      {/* <Header /> */}
      <Footer />
      {/* <WppButton /> */}
      <Manager />
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
